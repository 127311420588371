import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import APP_CONST from '@/constants/AppConst';
import { IAccount, ICreateUser, IProgram, ISite, IUserData, IUserRoles, roles } from '@/Model/programModel';
import APP_UTILITIES from "@/utilities/commonFunctions";
import { addUser, editPartnerUserDetails, getAccounts, getProgramByAccount, getSiteByProgramId, getUserEmailStatus } from '@/services/create-user/createUserService';
import ProgressButton from '@/commoncomponents/progressButton/progressButton.vue';
import { AddSiteDTO } from '@/services/program/adminService';
import DropdownList from '@/ui-components/dropdownList/DropdownList.vue';
import DatepickerComponent from '@/ui-components/datepicker/DatepickerComponent.vue';
import AccountAdminPopup from '@/popupcomponents/partnerAccountAdminPopup/accountAdminPopup.vue'
import UIkit from "uikit";
import { ISites } from '@/Model/programModel';
import { IAddSiteDTO } from '@/services/program/adminService';
import { getClassroomsBySite } from "@/services/create-user/createUserService";
import MANAGE_PROGRAM_CONST_NEW from '@/constants/manageProgramConstNew';

@Component({
    components: {
        'progress-button': ProgressButton,
        'dropdown': DropdownList,
        'datepicker': DatepickerComponent,
        'account-popup':AccountAdminPopup
    }
})
export default class createUpdatePartner extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    user: ICreateUser = JSON.parse(JSON.stringify(APP_CONST.PARTNER_USER_CREATION));
    validatedFields = JSON.parse(JSON.stringify(APP_CONST.VALIDATE_PARTNER_USER_FORM));
    accounts: IAccount[] = [] as IAccount[];
    account: IAccount = {id:0, name:''};
    status = {label: 'Status',id:0, value: '',required: false, disable: false, 
             dropdownList: [{ id: 1, value: "Active" },{ id: 2, value: "Inactive" }]}
    allSites : IAddSiteDTO[] = [] as IAddSiteDTO[]; 
    staffObj = JSON.parse(JSON.stringify(MANAGE_PROGRAM_CONST_NEW.STAFF_PAYLOAD_NEW));        
    allFieldRequired = false;
    accountDropdown = false;
    duplicateUser = false;
    isApiError = false;
    isAccountAdmin = false;
    isPrimaryAccountAdmin = false;
    isValid = true;
    isPhoneLengthValid: boolean | null = null;
    apiErrorMessage = '';
    validEmail: null | boolean = null;
    searchAccount = '';
    page = 1;
    apiResponseCode = 0;
    systemRoles: {id: number, name: string}[] = APP_CONST.SYSTEM_ROLE;
    userRoles: IUserRoles[] = [] as IUserRoles[]; 
    public sites: ISite[] = [] as ISite[];
    public programs: IProgram[] = [] as IProgram[]; 
    userDeletionIdx = -1;
    public hoverStyleObj:any = {};
    public hover:boolean = false;
    public hoverText:string = '';
    public hoverLabel:string = '';
    toggleIdx = -1;
    checkInactiveDate: boolean=true;
    updateProgress:boolean = false;
    accountAdminId = APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN;
    accountRoleId = 0;
    isPopupOpen:boolean=false;    
    public isMobileView:boolean=false;
    public disableStatus:boolean=false;
    public viewPopup:boolean = false;
    public infoIconShow: boolean = false;
    public siteDrop: boolean = false;
    public classDrop: boolean = false;
    public ids: any = APP_UTILITIES.coreids;
    public siteDeletionIdx: number = -1;
    public accordianIsClosed : boolean = false;
    public siteError: boolean = false;
    public sitesPresent: boolean = false;
    public editModeEmail : string = "";

    get filteredAccounts() {
        const searchAccount = this.searchAccount.toLowerCase();
        return searchAccount ? this.accounts.filter((account) => account.name.toLowerCase().includes(searchAccount)) : this.accounts;
    }

    get filteredPrograms() {
        const programs = this.programs;
        const userRoles = this.userRoles;
            const filtered = programs.filter(el => {
               return !userRoles.find(element => {
                   return element.programId === el.id;
               });
            });
            return filtered;
    }

    isMobile(){
        this.isMobileView =APP_UTILITIES.isMobile()
    }

    get disableButton() {
        let accordianFilled = true;
        if(this.user.status !== APP_CONST.TWO) { 
            const roles = this.userRoles.filter((role) => {
               return (!role.disableState || role.disableState === 1);
            })
            if(!roles.length && !this.user.userRoles.length) {
                accordianFilled = false;
            }
        this.userRoles.length && this.userRoles.forEach((el) => {
            if(!el.roleId || ((el.roleId === APP_CONST.PROGRAM_ADMIN_ROLE_ID) && !el.programId) ||
            (!this.isAccountAdmin && (el.roleId === APP_CONST.SESSION_ADMIN_ROLE_ID)
            && (!el.programId || !el.sites.length))) {
                    accordianFilled = false;
                }
            })
        
        const rolesToVerify = this.userRoles.filter((user) => user.roleId > 6);
            for(let userRole of rolesToVerify) {
                const unFilledSite = userRole.sites.filter((site) => !site.siteId);
                if(unFilledSite.length) {
                    accordianFilled = false;
                    break;
                }
            }

        this.checkInactiveDate=true;
        } else if(this.user.status==APP_CONST.TWO && !this.user.inactiveDate){
            this.checkInactiveDate=false;
        }

        if(!this.user.firstName || !this.user.lastName || (this.emailMandat ? !this.user.email : false) || (!this.isAccountAdmin && !this.userRoles.length) || (this.userRoles.length && !accordianFilled) || !this.account.id || !this.checkInactiveDate) {
            return true; 
        } else {
            return false;
        }
    }

    get emailMandat() {
        let emailMandatory = false;
        const allRoles = [...this.user.userRoles, ...this.userRoles];
            for(const role of allRoles) {
                if([4,5,6,7].includes(role.roleId)) {
                    emailMandatory = true;
                }
            }
        return emailMandatory;
    }

    @Prop()
    partnerUserDetails!:IUserData

    @Watch('partnerUserDetails', {deep: true})
    partnerUserData(userInfo: IUserData) {
        const partneruser = JSON.parse(JSON.stringify(userInfo));
        if(partneruser.userDetails && partneruser.userDetails.id) {
            this.partnerUser(partneruser);
        }
        return partneruser;
    }

    partnerUser(partnerUser: {userDetails: ICreateUser, userRole: IUserRoles[], accountRoleToMap: {id:number, name: string}, isPrimaryAccountAdmin: boolean, isAccountAdmin: boolean}) {
        this.user = partnerUser.userDetails;
        let loggedInUserId:any= APP_UTILITIES.getCookie('user_id');
        if(Number(loggedInUserId)==partnerUser.userDetails.id){
            this.disableStatus=true;
        }
        if(this.user.id){
            this.editModeEmail = this.user.email;
        }
        const status = this.status.dropdownList.find((el) => el.id === (this.user.status === 2 ? 2 : 1));
        if(status) {
            this.status.id = status.id;
            this.status.value = status.value;
            this.status.disable=this.disableStatus;
            this.selectStatus(this.status);
        }
        this.selectAccount(partnerUser.accountRoleToMap, false);
        this.userRoles = partnerUser.userRole;
        this.isAccountAdmin = partnerUser.isAccountAdmin;
        this.isPrimaryAccountAdmin = partnerUser.isPrimaryAccountAdmin;
        this.userRoles.forEach((userRole, index) => {
            if(userRole.roleId > 6) {
                userRole.sites.forEach((site) => {
                    this.getClassroomsBySiteId(site, index, true);
                })
            }
        })
    }

    @Emit('checkAddStatus') updateParentList(status:any) { }

    mounted() {
        this.isMobileView =APP_UTILITIES.isMobile()
        window.addEventListener("resize", APP_UTILITIES.debounce(this.isMobile));
        this.getAccountsDetails();
        this.collectAccountInfo();
    }

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    collectAccountInfo() {
       const accountDetails = APP_UTILITIES.getCookie('dlObject');
       const accountData = accountDetails && JSON.parse(accountDetails) || {}
        this.accountRoleId = accountData && accountData.UserRole || 0;
        const {accountId, roleId} = APP_UTILITIES.coreids();
        const accInfo = {id : accountId, name : accountData && accountData.AccountName};
        this.accountRoleId=roleId;
        if(this.accountRoleId === this.accountAdminId){
            this.selectAccount(accInfo);
        }
    }

    getAccountsDetails(){
        getAccounts().then((res:any) => {
            if(res.status === APP_CONST.RESPONSE_200){
                const data = res.data;
                this.accounts = data;
            }
        })
    }

    openDropdown(type: "accountDropdown", close?: boolean) {
        this[type] = typeof close === "boolean" ? close : !this[type];
        if(!this[type]) {
            this.clearSearch("searchAccount");
        }
    }

    selectAccount(account: {id:number, name: string}, close?: boolean) {
        if(this.account.id !== account.id) {
            this.account.id = account.id;
            this.account.name = account.name;
            if(this.account.id) {
                this.getPrograms(account.id);
            } else {
                this.programs = [] as IProgram[];
            }
            this.userRoles = [] as IUserRoles[];
        }
        this.account.error = false;
        this.openDropdown("accountDropdown", close);
        this.clearSearch("searchAccount");
        if(this.isAccountAdmin) {
            this.addAccountAdminId();
        }
    }

    selectRole(userRole: IUserRoles, role: {id: number, name: string}, index: number) {
        if(role.id !== userRole.roleId) {
            userRole.roleId = role.id;
            userRole.roleName = role.name;
            this.selectProgram(userRole, {id: 0, name: 'Select'}, index)
        }
        userRole.roleDrop = false;
        userRole.programError = false;
        userRole.siteError = false;
    }

    selectProgram(userRole: IUserRoles, program: {id: number, name: string}, index: number) {
        userRole.id = APP_CONST.ZERO;
        userRole.programId=program.id;
        userRole.progName=program.name;
        userRole.progDrop=false;
        userRole.sites = [];
        userRole.checkAllSite = false;
        if(userRole.programId) {
        this.getSite(userRole);
        } else {
            userRole.siteList = [] as AddSiteDTO[];
            this.allSites = [];
        }
        const siteId = APP_UTILITIES.getCookie("siteId");
        const parsedSiteId = siteId && Number(siteId) || 0;
        if (userRole.roleId === 7 || userRole.roleId == 9) {
            this.addClassOnSite(userRole);
            if (parsedSiteId && this.allSites.length == 1 && this.allSites[0].siteName !== undefined && this.allSites[0].siteId !== undefined) {
                let site = {siteId: this.allSites[0].siteId, siteName: this.allSites[0].siteName, checked: true}
                this.selectSiteForStaff(this.userRoles[0].sites[0], site, userRole, index);
            }
        }
        this.siteDeletionIdx = -1;
        this.hideTooltip();
    }

    selectSiteForStaff(site: ISites, selectedSite: {id?: number, siteId: number, siteName: string, status?: number},userRole:IUserRoles, index: number){
        site.id = selectedSite.id || 0;
        site.siteId = selectedSite.siteId;
        site.siteName = selectedSite.siteName;
        site.siteDrop = false;
        site.classes = [];
        site.classList = [];
        site.checkAllClass = false;
        site.siteSelectionError = selectedSite.siteId ? false : true;
        site.status = selectedSite.status;
        if (site.siteId) {
            this.getClassroomsBySiteId(site, index);
        }
        site.error = false;
        this.siteValidation(userRole);
    }

    get filteredSites() {
        const sites = this.allSites;
        let siteList : any[] = [];
        this.userRoles.forEach(element => {
            siteList = siteList.concat(element.sites.map((el) => {return el.siteId}))
        });
        const filtered = sites.filter((el: IAddSiteDTO) => {
            return !siteList.includes(el.siteId)
        });
        return filtered;
    }

    filterList(userRole: IUserRoles) {
         let siteList = userRole.sites.map((el) => el.siteId);
         const filteredList = userRole && userRole.siteList && userRole.siteList.filter((el) => !siteList.includes(el.siteId));
         return filteredList;
    }

    selectSite(userRole: IUserRoles, site: {siteId: number, siteName: string, checked?: boolean, userStatus: number}) {
        if(site.checked) {
            userRole.sites.push({id: 0, siteId: site.siteId, siteName: site.siteName, checked: site.checked, status: site.userStatus});    
        } else if(!site.checked) {
            const idx = userRole.sites.findIndex((el) => el.siteId === site.siteId);
            if(idx >= 0) {
                userRole.sites.splice(idx, 1);
            }
        }
        userRole.checkAllSite = userRole.sites.length === userRole.siteList.length ? true : false; 
    }

    selectAllSite(userRole: IUserRoles) {
        userRole.checkAllSite = !userRole.checkAllSite;
        if(userRole.checkAllSite) {
            userRole.siteList.forEach((el) => {
                if (el.userStatus === 1) {
                    el.checked = true;   
                } else {
                    userRole.checkAllSite = true;
                }
            });
            userRole.sites = userRole.siteList.map((el) => {return{id:0, siteId: el.siteId, siteName: el.siteName, checked: true, status: el.userStatus}}) || [];
        } else {
            userRole.siteList.forEach((el) => {
                if (el.userStatus === 1) {
                    el.checked = false
                } else {
                    userRole.checkAllSite = false;
                }
            });
            userRole.sites = userRole.sites.filter((site) => {
                if (site.status === 2) {
                    return site;
                }
            })
        }
    }

    selectStatus(status: {id: number, value: string}) {
        if(this.user.status !== status.id) {
            this.user.status = status.id;
            this.user.inactiveDate = this.user.status === 1 ? '' : this.user.inactiveDate;
        }
        if(this.user.status === 2) {
            this.closeAllAccordions();
        } else {
            this.activateAllRoles();
        }
    }

    onChange() {
        if(this.isAccountAdmin) {
            const siteUserRoles = this.userRoles.filter(el => {
                return el.roleId > APP_CONST.FIVE;
            });
            if (UIkit.modal('#account-admin-popup') && siteUserRoles.length) {
                UIkit.modal('#account-admin-popup').show();
                this.isPopupOpen=true
            }
            this.user.userRoles.push({
                id:0,
                roleId: APP_CONST.ACCOUNT_ADMIN_ROLE_ID, 
                accountId: this.account.id,
                programId: 0,
                siteId: 0,
                status: 1,
                inactiveDate: '',
                isPrimaryAccountAdmin: this.isPrimaryAccountAdmin,
            })
            this.user.accountName = this.account.name; 
            this.changeUserRole();

        } else {
            this.closeAccountPopup();
            const idx = this.user.userRoles.findIndex((el) => el.roleId === APP_CONST.ACCOUNT_ADMIN_ROLE_ID);
            if(idx>= 0) {
                this.user.userRoles.splice(idx, 1);
                this.user.accountName = '';
            }
        }
        this.isPrimaryAccountAdmin = this.isAccountAdmin ? this.isPrimaryAccountAdmin : false;
    }

    addAccountAdminId() {
        const idx = this.user.userRoles.findIndex((el) => el.roleId === APP_CONST.ACCOUNT_ADMIN_ROLE_ID);
        if(idx >= 0) {
            this.user.userRoles[idx].accountId = this.account.id;
            this.user.accountName = this.account.name;
        }
    }

    primaryAccount() {
        if(this.user.userRoles.length) {
            const idx = this.user.userRoles.findIndex((el) => el.roleId === APP_CONST.ACCOUNT_ADMIN_ROLE_ID);
            this.user.userRoles[idx].isPrimaryAccountAdmin = this.isPrimaryAccountAdmin;
        }
    }

    removeProgramRole(index: number) {
        this.userRoles.splice(index, 1);
        this.stopDeletion();
    }

    stopDeletion() {
        this.userDeletionIdx = -1;
    }

    validate() {
        this.apiErrorMessage = '';
        this.duplicateUser = false;
        this.isPhoneLengthValid = null;

        const ValidateObj = JSON.parse(JSON.stringify(APP_CONST.VALIDATE_PARTNER_USER_FORM));
        const validation = APP_UTILITIES.requiredFields(this.user, ValidateObj);
        this.validatedFields = {...validation.validateData};
        this.allFieldRequired = validation.requiredFieldsFilled;
        const noAccountSelected = !this.account.id;
        if (validation.requiredFieldsFilled || noAccountSelected) {
            this.account.error = noAccountSelected;
            this.allFieldRequired = validation.requiredFieldsFilled || noAccountSelected;
            return false;
        }
        if(validation.isFormInvalid) {
            return false;
        }

        const validDomains = APP_CONST.NON_BX_VALID_EMAILS;
        const higherRole = Math.min(...this.user.userRoles.map((userDt: roles) => userDt.roleId), ...this.userRoles.map((userDt: roles) => userDt.roleId));
        if(this.emailMandat || this.user.email) {
            this.validEmail =  APP_UTILITIES.validateEmail(this.user.email, validDomains, higherRole);
            if(!this.validEmail) return false;
        }

        this.user.phoneNumber = APP_UTILITIES.removeSpecialCharacters(this.user.phoneNumber);
        if(this.user.phoneNumber && (this.user.phoneNumber.length !== 10)) {
            this.isPhoneLengthValid = false;
            return false;
        } else {
            this.isPhoneLengthValid = true;
        }

        if(this.validatedFields.firstName.alphaCharError || this.validatedFields.lastName.alphaCharError) {
            return false;
        }
        return true;
    }

   async checkUsersEmail() : Promise<boolean> {
       if(this.emailMandat) {
       let result = await getUserEmailStatus(this.user.email, this.user.id ? this.user.id : undefined);
        if(result.status === APP_CONST.RESPONSE_204) {
            if(!this.user.id) {
                const page = 2;
                this.navToPage(page);
            }
            return true;
        } else if(result.status === APP_CONST.RESPONSE_406) {
            this.duplicateUser = true;
            return false;
        } else {
            return true;
        }
    } else {
        if(!this.user.id) {
            const page = 2;
            this.navToPage(page);
        }
        return true;
    }
    }

    validateWhileAddingUser() {
        const isValid  = this.validate();
        if(!isValid) {
            return;
        }
        this.checkUsersEmail();
    }

    updateStruecture() {
        this.isValid = false;
        this.apiResponseCode = 0;
        this.isApiError = false;
        let partnerRoles = [] as roles[];
        const userRoles = this.user.userRoles.filter((el) => el.roleId === APP_CONST.ACCOUNT_ADMIN_ROLE_ID);
        const filteredRoles = this.userRoles.filter((el) => {
        if(!el.roleId || ((el.roleId === APP_CONST.PROGRAM_ADMIN_ROLE_ID) && !el.programId) ||
            ((el.roleId === APP_CONST.SESSION_ADMIN_ROLE_ID || el.roleId === APP_CONST.ROLE_TYPE_STAFF) && (!el.programId || !el.sites.length))) {
                return false;
            }
            if(!this.isAccountAdmin){
                if(el.sites.length) {
                    el.sites.forEach((site) => {
                        partnerRoles.push({...{id:site.id || 0,roleId:el.roleId,programId: el.programId,accountId:el.accountId, isPrimaryAccountAdmin: el.isPrimaryAccountAdmin, programRole: el.programRole, status: site.status }, siteId: site.siteId, userRoleClassrooms: (site.classes && site.classes.length) ? site.classes.map(el => {return {id: el.id ? el.id : 0, classRoomId: el.classRoomId ? el.classRoomId : 0, userRoleId: el.userRoleId, classRoomName: el.classRoomName}}) : []
                    })
                    })
                    return false;
                }
            }
            else if(el.roleId!==APP_CONST.PROGRAM_ADMIN_ROLE_ID){
                    return false;
            }
            return true;
        }).map(({id, roleId,programId,accountId,isPrimaryAccountAdmin,siteId,programRole, status}) => ({id, roleId,programId,accountId,isPrimaryAccountAdmin,siteId,programRole,status}));
        this.user.userRoles = userRoles.concat([...filteredRoles, ...partnerRoles]);
        
    }

    createUser() {
        this.updateStruecture();
        addUser(this.user).then((res: any) => {
            if(res.status === APP_CONST.RESPONSE_200) {
                this.apiResponseCode = APP_CONST.RESPONSE_200;
                let update = false;
                for(let role of this.user.userRoles) {
                    if([4,5,6,7].includes(role.roleId)) {
                        update = true;
                        break;
                    }
                }
                this.updateParentList({status: true, update: update});
                this.refillForm();
                this.close();
            } else if(res.status === APP_CONST.RESPONSE_400) {
                this.apiResponseCode = APP_CONST.RESPONSE_400;
                this.isApiError = true;
                this.apiErrorMessage=res.data;
             } else if(res.status === APP_CONST.RESPONSE_500) {
                this.refillForm();
             }
        })
    }

   async updateUser() {
        const isValid  = this.validate();
        let emailValid = true;
        if(this.editModeEmail!==this.user.email){
            emailValid = await this.checkUsersEmail();
        }
        if(!isValid || !emailValid) {
            return;
        }
        this.updateStruecture();
        this.updateProgress = true;
        editPartnerUserDetails(this.user).then((res: any) => {
            if(res.status === APP_CONST.RESPONSE_200) {
                this.apiResponseCode = APP_CONST.RESPONSE_200;
                this.refillForm();
                this.updateParentList({status: true, update: false});
                this.close();
            }else if(res.status === APP_CONST.RESPONSE_400) {
                this.apiResponseCode = APP_CONST.RESPONSE_400;
                this.isApiError = true;
                this.apiErrorMessage=res.data;
             }else if(res.status === APP_CONST.RESPONSE_500) {
                this.refillForm();
             }
        })
    }

    getPrograms(accountId: number){
        getProgramByAccount(accountId).then((res:any) => {
            /* istanbul ignore else */
            if(res.status === APP_CONST.RESPONSE_200){
                const data = res.data;
                this.programs = data.filter((program: any) => {
                    return program.status === 1;
                }) as IProgram[];
            } else {
                this.programs = [];
            }
        })
    }

    getSite(userRole: IUserRoles){
        getSiteByProgramId(userRole.programId).then((res:any) => {
            /* istanbul ignore else */
            if(res.status === APP_CONST.RESPONSE_200){
                const data = res.data;
                userRole.siteList = data;
                this.sitesPresent = userRole.siteList.length? true : false;
                if(data) {
                    this.allSites = data;
                    const siteId = APP_UTILITIES.getCookie("siteId");
                    const parsedSiteId = siteId && Number(siteId) || 0;
                    if(parsedSiteId) {
                        this.allSites = this.allSites.filter((el:any) => {
                            if (el.siteId == parsedSiteId){
                                return el;
                            }
                        })
                    }
                    if (this.staffObj.id > 0) {
                        this.checkedSitesForSessionAdmin();
                    }
                } 
                userRole.siteList.forEach((site) => {
                    site["userStatus"] = 1;
                })
            } else {
                userRole.siteList = [];
            }
        })
    }

    uniqueId() {
        const ids = this.userRoles.map((el)=> el.uId);
        let randomId = Math.floor(Math.random() * 10000) + 1;
        if(ids.includes(randomId)) {
            randomId = this.uniqueId();
        }
        return randomId;
    }

    changeStatus( userRole: IUserRoles) {
        userRole.status = userRole.status === 1 ? 2 : 1;
        userRole.sites.forEach((site) =>{
            site.status = userRole.status;
        });
        userRole.siteList.forEach((site) => {
            site.userStatus = userRole.status;
        });
    }

    AddProgramRoles() {
        if(this.accountRoleId === this.accountAdminId){
            this.collectAccountInfo();
        };
        this.closeAllAccordions();
        const programRoleToPush = {uId: this.uniqueId(), id:0, roleId: this.isAccountAdmin? 5 : 0, accountId: this.account.id, programId: 0, siteList:[], sites:[], siteId: 0, 
            programRole: '', inactiveDate: '', roleName: this.isAccountAdmin ? 'Program Admin' : 'Select', progName: 'Select', checkAllSite: false,
            isPrimaryAccountAdmin: false, roleDrop: false, progDrop: false, siteDrop: false, accordOpen: true, status: 1, systemRoleError: false, programError: false, siteError: false};
        this.userRoles.push(programRoleToPush);    
        this.userRoles = [...this.userRoles];
    }

    refillForm() {
        this.user = JSON.parse(JSON.stringify(APP_CONST.PARTNER_USER_CREATION));
        this.userRoles = [];
        this.validatedFields = JSON.parse(JSON.stringify(APP_CONST.VALIDATE_PARTNER_USER_FORM));
        const { roleId } = APP_UTILITIES.coreids();
        const accountId = roleId == APP_CONST.ACCOUNT_ADMIN_ROLE_ID ? this.account.id : APP_CONST.ZERO;
        const accountName = roleId == APP_CONST.ACCOUNT_ADMIN_ROLE_ID ? this.account.name : '';
        this.account = { id: accountId, name: accountName };
        this.validEmail = null;
        this.duplicateUser = false;
        this.isApiError = false;
        this.allFieldRequired = false;
        this.isPhoneLengthValid = null;
        this.isAccountAdmin = false;
        this.isPrimaryAccountAdmin = false;
        this.isValid = true;
        this.apiResponseCode = 0;
        this.page = 1;
        this.toggleIdx = -1;
        this.updateProgress = false;
        this.stopDeletion();
    }

    clearSearch(type: "searchAccount") {
        this[type] = '';
    }

    close() {
        this.disableStatus=false;
        this.$emit(APP_CONST.CLOSE, 'add-update-partner');
        this.refillForm();
        this.scrollReset();
        this.searchAccount='';
        this.viewPopup = false;
        this.infoIconShow = false;
    }

    navToPage(page: number) {
        this.page = page;
        this.scrollReset();
    }

    revertVal(type: "isAccountAdmin" | "isPrimaryAccountAdmin") {
        this[type] = !this[type]
       const callBack = type === "isAccountAdmin" ? this.onChange : this.primaryAccount;
       callBack();
    }

    scrollReset() {
        let elem:any  = document.getElementById('edit-partner') as HTMLElement ;
        elem.scrollTo(0,0);
    }

    closeAllAccordions() {
        this.userRoles.forEach((el) => {
            el.accordOpen = false;
            this.checkValidations(el);
        })
        this.toggleIdx = -1;
    }

    toggle(userRole: IUserRoles) {
        userRole.accordOpen = !userRole.accordOpen;
        this.toggleIdx = -1;
        this.checkValidations(userRole);
    }

    showTooltip(val : string, $event: any, id:string, isMobileView: string){
        this.hover = true;
        this.hoverText = val;
        this.hoverLabel = id;
        const boundBox = $event && $event.target.getBoundingClientRect();
        const coordX = boundBox.left;
        const coordY = boundBox.top;
        this.hoverStyleObj = {
            top:(coordY + 50).toString() + "px",
            left:(coordX + 50).toString() + "px",
            width: "fit-content;"
        }
    }

    hideTooltip(){
        this.hover = false;
        this.hoverText = '';
        this.hoverLabel = '';
        this.hoverStyleObj= {};
    }

    changeUserRole(){
        this.userRoles.forEach(element => {
            if(!element.roleId){
                element.roleId=APP_CONST.PROGRAM_ADMIN_ROLE_ID;
                element.roleName="Program Admin"
            }
        });
        this.closeAllAccordions()
    }

    viewAccountAdminInstructionPopup(){
        if(this.accountRoleId === this.accountAdminId){
            this.viewPopup = true;
        }
    }

    activateAllRoles() {
        this.userRoles.forEach((el) => {
            el.status = 1;
        })
    }

    closeAccountPopup() {
        if (UIkit.modal('#account-admin-popup')) {
            UIkit.modal('#account-admin-popup').hide();
            this.isPopupOpen=false;
        }
        if(this.isAccountAdmin) {
            const newUserRoles = this.userRoles.filter(el => {
                return el.roleId == APP_CONST.FIVE;
            });
            this.userRoles=[...newUserRoles]
        }
    }

   
    checkAccordionError(userRole: IUserRoles){
        return ((userRole.systemRoleError && !userRole.roleId) || (userRole.programError && !userRole.programId) || (userRole.siteError && !userRole.sites.length) || (userRole.roleId > 6 && userRole.siteError));
    }

    checkValidations(userRole: IUserRoles) {
        userRole.systemRoleError = false;
        userRole.programError = false;   
        userRole.siteError = false;         
        if(!userRole.roleId){
            userRole.systemRoleError = true;            
        } 
        if(!userRole.programId) {
            userRole.programError = true;
        }
        if((userRole.roleId === APP_CONST.SESSION_ADMIN_ROLE_ID && !userRole.sites.length)) {
            userRole.siteError = true;
        } 
        this.siteValidation(userRole);
    }

    siteValidation(userRole: IUserRoles) {
        userRole.siteError = false;
        if(userRole.roleId === APP_CONST.ROLE_TYPE_STAFF || userRole.roleId === APP_CONST.ROLE_TYPE_NONSYSTEM) {
            userRole.sites.forEach(site => {
                if(!site.siteId) {
                    site.siteSelectionError = true;
                    userRole.siteError = true;
                }
            })
        }
    }

    closeAccountInstructionPopup(){
        this.viewPopup = false;
    }    

    addClassOnSite(userRole: IUserRoles){
        const addSiteClass = {siteId: 0, siteName: 'Select', siteDrop: false, checkAllClass: false, classDrop: false, classList: [], classes: [], siteSelectionError: false};
        userRole.sites.push(addSiteClass);
    }

    getClassroomsBySiteId(site: ISites, index: number, fromEdit?:boolean) {
        const progId = this.userRoles[index].programId;
        if(!site.siteId) {
            return;
        }
        getClassroomsBySite(progId, site.siteId).then((reponse:any) => {
            if(reponse.status === APP_CONST.RESPONSE_200){
                const data = reponse.data;
                if(data){
                    site.classList = data.map((el: {id: number, classroomName: string, checked: boolean}) => {return{id: el.id, classroomName: el.classroomName, checked: false}}) || [];
                    if (this.staffObj.id > 0) {
                        this.checkedClassRoomsForStaff();
                    }
                    if(fromEdit && site.classList && site.classes) {
                        site.classList && site.classList.forEach(classFromList => {
                            site.classes && site.classes.forEach(classFromClasses => {
                                if (classFromClasses.classRoomId == classFromList.id ) {
                                    classFromList.checked = true;
                                    classFromClasses.classRoomName = classFromList.classroomName;
                                }
                            })
                        });
                        if(site.classList.length === site.classes.length) {
                            site.checkAllClass = true;
                        }
                    }
                }
            }
        })
    }

    checkedClassRoomsForStaff(){
        if (this.staffObj.id > 0 && this.userRoles.length && this.userRoles[0].roleId == 7 || this.userRoles[0].roleId == 9) {
            this.userRoles[0].sites.forEach(site => {
                site.classList && site.classList.forEach(classFromList => {
                    site.classes && site.classes.forEach(classFromClasses => {
                        if (classFromClasses.classRoomId == classFromList.id ) {
                            classFromList.checked = true;
                        }
                    })
                });
                if (site.classes && site.classList) {
                    site.checkAllClass = site.classes.length === site.classList.length ? true : false;
                }
            });
        }
    }
    
    checkedSitesForSessionAdmin() {
        if (this.staffObj.id > 0 && this.userRoles.length && (this.userRoles[0].roleId == 6) ) {
            for (const site of this.allSites) {
                for (const selectedSite of this.userRoles[0].sites) {
                    if (site.siteId == selectedSite.siteId) {
                        site.checked = true;
                        site.status = selectedSite.status;
                    }
                }
            }
            this.userRoles[0].checkAllSite = this.userRoles[0].sites.length === this.allSites.length ? true : false;
        }
    }

    selectAllClasses(site: ISites){
        if(site.checkAllClass) {
            site.classList && site.classList.forEach((el) => {el.checked = true});
            site.classes = site.classList && site.classList.map((el) => {return{id:0, classRoomId: el.id, classRoomName: el.classroomName, checked: true }}) || [];
        } else {
            site.classList && site.classList.forEach((el) => {el.checked = false});
            site.classes = [];
        }
    }

    selectClass(site: ISites, cls: {id?: number, classRoomId?: number, userRoleId?: number, classRoomName: string, classroomName?: string, checked: boolean}){
        if(cls.checked) {
            let idClass = 0;
            let classRoomIdClass = 0;
            let userRoleIdClass = 0;
            let classRoomNameClass = '';
            let checkedClass = cls.checked;
            if (cls.classRoomName) {
                classRoomNameClass = cls.classRoomName;
            } else if (cls.classroomName) {
                classRoomNameClass = cls.classroomName;
            }
            if (cls.classRoomId) {
                classRoomIdClass = cls.classRoomId;
                idClass = cls.id ? cls.id : 0;
                userRoleIdClass = cls.userRoleId ? cls.userRoleId : 0;
            } else {
                classRoomIdClass = cls.id ? cls.id : 0;
                idClass = 0;
                userRoleIdClass = 0;
            }
            let classToPush = {id: idClass, classRoomId: classRoomIdClass, classRoomName: classRoomNameClass, checked: checkedClass, userRoleId: userRoleIdClass};
            site.classes && site.classes.push(classToPush);
        } else if(!cls.checked) {
            const idx = site.classes && site.classes.findIndex((el) => el.classRoomId === cls.id);
            if((typeof idx !== 'undefined') && idx >= 0) {
                site.classes && site.classes.splice(idx, 1);
            }
        }
        if (site.classes && site.classList) {
            site.checkAllClass = site.classes.length === site.classList.length ? true : false;
        }
    }
    
    deleteSite(role: IUserRoles, index: number){
        if (role.sites.length && index > 0) {
            role.sites.splice(index, 1);
            this.siteValidation(role);
            this.stopSiteDeletion();
        }
    }

    stopSiteDeletion() {
        this.siteDeletionIdx = -1;
    }   
}
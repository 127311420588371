import APP_UTILITIES from "@/utilities/commonFunctions";
import { Vue } from "vue-property-decorator";
export default class MANAGE_PROGRAM_CONST_NEW extends Vue {

    public static coreIds = APP_UTILITIES.coreids();
    public static programId = APP_UTILITIES.getCookie("programId");
    public static readonly STAFF_OBJECT_NEW = {
        id: {
            value: 0,
            required: false,
        },
        programName: {
            value: 0,
            required: false
        },
        lastLogin: {
            value: "",
            required: false,
        },
        staffUser: {
            firstName: {
                label: "First Name",
                value: "",
                error: false,
                required: true,
                errorText: "",
            },
            lastName: {
                label: "Last Name",
                value: "",
                error: false,
                required: true,
                errorText: "",
            },
            email: {
                label: "",
                value: "",
                error: false,
                required: false,
                disable: false,
                errorText: ""
            },
            phoneNumber: {
                label: "",
                value: "",
                error: false,
                errorText: "Phone number doesn't start with 0",
                lengthError: false,
                lengthErrorText: "Phone number must be of 10 characters.",
                required: false,
            },
            inactiveDate: {
                value: "",
                toDate: "",
                fromDate: "",
                required: false,
                error: false,
                disable: false
            },
            personalInvitationNote: {
                label: "",
                value: "",
                required: false,
                error: false,
                errorText: "",
            },
            userRoles: [
                {
                    id: {
                        value: 0,
                        required: false,
                    },
                    roleId: {
                        id:9,
                        value: "Select",
                        error: false,
                        required: false,
                        errorText: "",
                        disable: true,
                        dropdownList: [
                            { id: 5, value: "Program Admin" },
                            { id: 6, value: "Session Admin" },
                            { id: 7, value: "Staff" }
                        ]
                    },
                    accountId: {
                        value: 0,
                        required: false,
                    },
                    programId: {
                        value: 0,
                        required: false,
                    },
                    siteId: {
                        id:0,
                        value: "Select",
                        label: "Site/Session Name",
                        error: false,
                        errorText: "",
                        required: true,
                        disable: false,
                        dropdownList: [
                            { id: 0, value: "Select" }
                        ],
                    },
                    programRole: {
                        value: "",
                        required: false,
                    },
                    status: {
                        value: 0,
                        required: false,
                    },
                    inactiveDate: {
                        value: "",
                        toDate: "",
                        fromDate: "",
                        required: false,
                        error: false,
                        disable: false
                    },
                    isPrimaryAccountAdmin: {
                        value: false,
                        required: false,
                    },
                },
            ],
            forgotPasswordGuid: {
                value: "",
                required: false,
            },
            resendBy: {
                value: "",
                required: false,
            },
            resendOn: {
                value: "",
                required: false,
            },
        },
        staffUserInformation: {
            id: {
                value: 0,
                required: false,
            },
            userId: {
                value: 0,
                required: false,
            },
            programId: {
                value: 0,
                required: false
            },
            customNotes: {
                value: "",
                required: false,
            },
            medicalCondition: {
                value: "",
                required: false,
            },
            familyFirstName: {
                label: "",
                value: "",
                error: false,
                errorText: "",
                required: false,
            },
            familyLastName: {
                label: "",
                value: "",
                error: false,
                errorText: "",
                required: false,
            },
            familyRelationship: {
                value: "",
                required: false,
            },
            familyPhoneNumber: {
                value: "",
                required: false,
                error: false,
                errorText: "",
            },
            familyEmail: {
                value: "",
                required: false,
                error: false,
                errorText: "",
            },
            status: {
                id: 0,
                label: 'Status',
                value: '',
                required: false
            }    
        },
        classInformation: [
            {
                id: {
                    value: 0,
                    required: false,
                },
                programId: {
                    value: 0,
                    required: false,
                },
                programName: {
                    value: 0,
                    required: false
                },
                userRoleId: {
                    value: 0,
                    required: false
                },
                siteId: {
                    value: 0,
                    required: false
                },
                siteName: {
                    value: '',
                    required: false
                },
                classes: [
                    {
                        classId: {
                            id:0,
                            value: "Select",
                            label: "Class Name",
                            error: false,
                            errorText: "",
                            required: true,
                            disable: false,
                            dropdownList: [
                                { id: 0, value: "Select" }
                            ],
                        },
                        className: {
                            value: '',
                            required: false
                        }
                    }  
                ]
            }
        ],
        inviteStatus: {
            id: {
                value: 0,
                required: false
            },
            name: {
                value: '',
                required: false
            },
            accountId: {
                value: 0,
                required: false,
            },
            primaryAccountAdmin: {
                value: false,
                required: false
            }
        }
    }

    public static readonly USER_ROLE = {
        id:0,
        value: "Select",
        error: false,
        required: false,
        errorText: "",
        disable: false,
        dropdownList: [
            { id: 5, value: "Program Admin" },
            { id: 6, value: "Session Admin" },
            { id: 7, value: "Staff" },
            { id: 9, value: "No Access" }
        ]
    }

    public static readonly USER_STATUS = {
        id: 1,
        value: 'Active',
        label: 'Status',
        required: false,
        disable: false,
        dropdownList: [
            { id: 1, value: "Active" },
            { id: 2, value: "Inactive" }
        ]
    }

    public static readonly SITES = {
        value: "Select",
        error: false,
        required: false,
        errorText: "",
        disable: true,
        dropdownList: []
    }

    public static readonly USER_ROLES = {
        id: 0,
        uId: 0,
        roleId: 0,
        accountId: MANAGE_PROGRAM_CONST_NEW.coreIds.accountId,
        accountName: "",
        programId: MANAGE_PROGRAM_CONST_NEW.programId && Number(MANAGE_PROGRAM_CONST_NEW.programId),
        programName: "",
        siteId: 0,
        siteName: "",
        programRole: "",
        status: 1,
        inactiveDate: "",
        isPrimaryAccountAdmin: false,
        sites: [],
        siteList: [],
        checkAllSite: false,
        siteDrop: false,
        roleName: '',
        progName: ''
    }

    public static readonly STAFF_INFO_ACCORD = {
        firstName: {
            value: "",
            alphaCharError: false,
            errorAlpha: 'Sorry, please use only alphabetic characters. Hyphens and apostrophes are also allowed.',
            error: false,
        },
        lastName: {
            value: "",
            alphaCharError: false,
            errorAlpha: 'Sorry, please use only alphabetic characters. Hyphens and apostrophes are also allowed.',
            error: false,
        },
        email: {
            value: "",
            required: false,
            error: false,
            type: 'email',
            errorText: "Email format is invalid."
        },
        phoneNumber: {
            value: "",
            type: 'number',
            error: false,
            errorText: "Phone number doesn't starts with 0.",
            lengthError: false,
            lengthErrorText: "Phone number must be of 10 characters."
        }
    } 

    public static readonly STAFF_FAMILY_ACCORD = {
        familyFirstName: {
            value: "",
            alphaCharError: false,
            errorAlpha: 'Sorry, please use only alphabetic characters. Hyphens and apostrophes are also allowed.'
        },
        familyLastName: {
            value: "",
            alphaCharError: false,
            errorAlpha: 'Sorry, please use only alphabetic characters. Hyphens and apostrophes are also allowed.'
        },
        familyEmail: {
            value: "",
            required: false,
            error: false,
            type: 'email',
            errorText: "Email format is invalid."
        },
        familyPhoneNumber: {
            value: "",
            type: 'number',
            error: false,
            errorText: "Phone number doesn't starts with 0.",
            lengthError: false,
            lengthErrorText: "Phone number must be of 10 characters."
        }
    }

    public static readonly STAFF_PAYLOAD_NEW = {
        id: 0,
        lastLogin: "",
        staffUser: {
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            inactiveDate: "",
            personalInvitationNote: "",
            userRoles: [
                MANAGE_PROGRAM_CONST_NEW.USER_ROLES  
            ],
            forgotPasswordGuid: "",
            resendBy: 0,
            resendOn: "",
            userInformation: { 
                id: 0,
                userId: 0,
                customNotes: "",
                medicalCondition: "",
                familyFirstName: "",
                familyLastName: "",
                familyRelationship: "",
                familyPhoneNumber: "",
                familyEmail: "",
                status: 0,
            }
        },
    }


    public static readonly DUMMY_STAFF_PAYLOAD_NEW = {
        id: 0,
        programName: "Piyush program_demo2 name is very lenghty to check the tooltip length and verify a bug raised by QA",
        siteName: "aa",
        systemRole: "Program Admin",
        lastLogin: "2021-05-11T06:09:28.912Z",
        staffUser: {
            firstName: "userFirst",
            lastName: "userLast",
            email: "sujit.bidawe+userFirst@magicedtech.com",
            phoneNumber: "9999990000",
            inactiveDate: "2021-05-11T06:09:28.912Z",
            personalInvitationNote: "this is a personal invitation note",
            userRoles: [
                {
                    id: 0,
                    roleId: 5,
                    accountId: 4,
                    accountName: "",
                    programId: 5,
                    programName: "",
                    siteId: 0,
                    siteName: "",
                    programRole: "",
                    status: 0,
                    inactiveDate: "2021-05-11T06:09:28.912Z",
                    isPrimaryAccountAdmin: false
                }
            ],
            forgotPasswordGuid: "string",
            resendBy: 0,
            resendOn: "2021-05-11T06:09:28.912Z"
        },
        userInformation: {
            id: 0,
            userId: 0,
            programId: 5,
            customNotes: "i am a custom note",
            medicalCondition: "no",
            familyFirstName: "familyFirst",
            familyLastName: "familyLast",
            familyRelationship: "family",
            familyPhoneNumber: "9898980000",
            familyEmail: "sujit.bidawe+family@magicedtech.com",
            status: 0
        },
        classInformation: [
            {
                id: 0,
                programId: 0,
                programName: "string",
                userRoleId: 0,
                siteId: 0,
                siteName: "string",
                classRoomIds: [
                0
                ],
                classRoomNames: [
                "string"
                ]
            }
        ],
        inviteStatus: {
            id: 0,
            name: "string",
            accountId: 0,
            primaryAccountAdmin: false
        }
    }
}

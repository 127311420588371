import { Vue, Component} from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';

@Component({})
export default class AccountAdminPopup extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public roleId: number = 0;
    
    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    close(){
        this.$emit('close', {id:'account-admin-popup'});
    }
    goBack(){
        this.$emit('goBack');
    }
}
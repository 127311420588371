import { render, staticRenderFns } from "./TableComponent.vue?vue&type=template&id=44ea5398&scoped=true&"
import script from "./TableComponent.vue?vue&type=script&lang=ts&"
export * from "./TableComponent.vue?vue&type=script&lang=ts&"
import style0 from "./TableComponent.less?vue&type=style&index=0&id=44ea5398&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44ea5398",
  null
  
)

export default component.exports
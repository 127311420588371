









































































































































































































































































































































































































































import createUpdatePartner from './createUpdatePartner';
export default createUpdatePartner;






























































import AccountAdminPopup from '@/popupcomponents/partnerAccountAdminPopup/accountAdminPopup';
export default AccountAdminPopup;

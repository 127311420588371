






























































































import ReadOnlyUserDetails from "@/popupcomponents/readonlyuserdetails/ReadonlyUserDetails";
export default ReadOnlyUserDetails;

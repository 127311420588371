




















































































































































































































































































































































































































































































































































































import TableComponent from "@/commoncomponents/tablecomponent/TableComponent";
export default TableComponent;

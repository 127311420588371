import { Vue, Component, Prop } from 'vue-property-decorator'
import { ScreenText } from '@/lang/ScreenText'


@Component
export class InvitationSentComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();

    @Prop()
    manageButton!: string;

    @Prop()
    body!: string

    public getScreenText(key: string): string {
        return this.objScreenText.getScreenText(key);
    }

    public backToHome(modal: string, home: boolean) {
        this.$emit('close', {goToHome: home, modal: modal});
    }

    public addAccount() {
        this.$emit('clear');
    }

    public addNewProgram(){
        this.backToHome('modal-invitation-sent', false)
        this.$router.push('/accounts/createprogram');
    }

}